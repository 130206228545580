//bs-collections
.bs-collections {
    $self: &;
    @include mq-below($sm){
        padding-block-end: rem(60px);
    }

    &__item {
        width: rem(550px);
        height: rem(634px);
        border-radius: rem(24px);
        overflow: hidden;
        margin-block-end: rem(20px);
        @include mq-below($sm) {
            width: 100%;
            height: rem(460px);
            margin-block-end: rem(20px);
        }
        @include mq-between($sm2, $lg){
            @include orientation(portrait){
                width: rem(350px);
                height: rem(403px);
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        transform: scale(1.05);
    }

    &__swiper-slide {
        transition: all 0.3s ease;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        &:hover {
            #{$self}__image {
                transform: scale(1);
            }
        }

        &:not(&.swiper-slide-active) {
            transform: scale(0.75);
        }

        &:not(&.swiper-slide-active, &.swiper-slide-prev, &.swiper-slide-next) {
            opacity: 1;
        }
    }

    &__swiper-container {
        overflow: visible !important;
        padding-inline: rem(60px);
        width: 100%;

        .swiper-button-prev,
        .swiper-button-next {
            width: rem(50px);
            height: rem(50px);
            @include mq-below($sm){
                display: none;
            }
            @include mq-between($sm2, $lg){
                @include orientation(portrait){
                    display: none;
                }
            }
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: 0 0;
                background-repeat: no-repeat;
            }
        }

        .swiper-button-prev {
            left: rem(-50px);
            @include mq-below($sm) {
                left: rem(-20px);
            }
            &:after {
                @include svg-icon("../../images/icons/arrow-left.svg", rem(50px), rem(50px));
                @include background-opacity($clr-dark, 1);
            }
        }

        .swiper-button-next {
            right: rem(-50px);
            @include mq-below($sm) {
                right: rem(-20px);
            }
            &:after {
                @include svg-icon("../../images/icons/arrow-right.svg", rem(50px), rem(50px));
                @include background-opacity($clr-dark, 1);
            }
        }

        .swiper-pagination{
            bottom: rem(-56px) !important;
            top: auto !important;
            display: none;

            @include mq-below($sm){
                display: block;
            }

            @include mq-between($sm2, $lg){
                @include orientation(portrait){
                    display: block;
                    bottom: rem(-32px) !important;
                }
            }

            .swiper-pagination-bullet{
                width: rem(9px);
                height: rem(9px);
                margin: 0 rem(5px) !important;

                &.swiper-pagination-bullet-active{
                    background-color: $clr-dark;
                }
            }
        }
    }
}
