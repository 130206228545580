//bs-mobile-filter
.bs-mobile-filter{
    $self: &;

    &__action{
        padding: rem(8px);
        border-color: $clr-dark;
    }

    &__body{

        .MuiPaper-root{
            padding: rem(32px) rem(20px);
        }

        .bs-content-filter{

            &__sort-wrap{
                width: 100%;
            }

            &__sort-wrap-list{
                

                @include mq-below($sm){
                    flex-direction: column;
                    gap: rem(24px);
                }
        
                @include mq-between($sm, $md2){
                    @include orientation(portrait){
                        flex-direction: row;
                        gap: rem(24px);
                    }
                }
            }

            &__sort-wrap-list-item{
                .bs-select{
                    align-items: flex-start;

                    &__widget{
                        width: 100%;
                    }
                }
            }
            
        }

        &__action-wrap{
            margin-block-start: rem(32px);

            .bs-btn{
                width: 100%;
            }
        }
    }


}