//bs-banner
.bs-banner {
    $self: &;
    height: calc(100vh - rem(156px));
    overflow: hidden;
    @include mq-below($sm) {
        height: calc(100vh - rem(72px));
    }

    @include mq-between($sm, $md2){
        @include orientation(portrait){
            height: auto;
        }
    }

    &__swiper-container {
        height: 100%;

        .swiper-button-prev,
        .swiper-button-next {
            width: rem(50px);
            height: rem(50px);
            &:after {
                content: "";
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: 0 0;
                background-repeat: no-repeat;
            }
        }

        .swiper-button-prev {
            left: rem(96px);
            &:after {
                background-image: url("../../images/icons/arrow-left.svg");
            }
        }

        .swiper-button-next {
            right: rem(96px);
            &:after {
                background-image: url("../../images/icons/arrow-right.svg");
            }
        }
    }

    &__swiper-slide {
        position: relative;

        #{$self}__slide-info {
            position: absolute;
            left: 0;
            right: 0;
            bottom: rem(50px);
            z-index: 1;
            text-align: center;

            
        }
    }

    &__slide-title {
        font-size: $fs-h1;
        line-height: $lh-h1;
        color: $clr-white;
        font-family: $font2;
        margin-block-end: rem(30px);

        @include mq-below($md) {
            font-size: $fs-h3;
            line-height: $lh-h3;
        }
    }

    &__video-wrap {
        height: 100%;
        position: relative;

        #{$self}__slide-info {
            position: absolute;
            left: 0;
            right: 0;
            bottom: rem(50px);
            z-index: 1;
            text-align: center;

            @include mq-below($md){
                text-align: left;
                left: rem(20px);
            }

            @include mq-between($sm, $md2){
                @include orientation(portrait){
                    text-align: center;
                }
            }
        }

        &__action{
            position: absolute;
            bottom: rem(50px);
            left: rem(96px);
            z-index: 2;
            @include mq-below($md){
                right: rem(20px);
                left: auto;
            }

            @include mq-between($sm, $lg){
                @include orientation(portrait){
                    right: rem(20px);
                    left: auto;
                }
            }
        }
    }

    &__video, video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        @include mq-below($sm){
            object-position: 65%;
        }
        @include mq-between($sm, $md2){
            @include orientation(portrait){
                object-fit: contain;
            }
        }
    }

    &--typ-small{
        height: 50vh;
        @include mq-below($sm){
            height: 35vh;
        }
        
        @include mq-between($sm, $lg){
            @include orientation(portrait){
                height: 30vh;
            }
        }

        #{$self}__media{
            width: 100%;
            height: 100%;
        }

        #{$self}__image{
            object-fit: fill;

            @include mq-below($sm){
                object-fit: cover;
            }
        }

    }
}
