//lyt-locator
.lyt-locator{
    $self: &;

    &__head-wrap{
        margin-block-end: rem(36px);
        display: flex;
        align-items: flex-end;
        gap: 0 rem(90px);

        @include mq-below($md){
            flex-direction: column;
            gap: rem(24px) 0;
        }

        @include mq-between($md, $lg){
            @include orientation(portrait){
                gap: 0 rem(60px);
            }
        }
    }

    &__search-label{
        font-size: $fs-h3;
        line-height: $lh-h3;
        font-weight: $font-regular;
        display: inline-block;
        margin-block-end: rem(8px);

        @include mq-below($md){
            font-size: $fs-h6;
            line-height: $lh-h6;
        }
    }

    &__separator{
        font-size: $fs-h5;
        line-height: $lh-h5;
        font-weight: $font-regular;

        @include mq-below($md){
            display: none;
        }
    }

    &__search-field-wrap{
        flex-grow: 1;
        @include mq-below($md){
            width: 100%;
        }
    }

    &__search-field{
        height: rem(62px);

        .MuiAutocomplete-root, 
        .MuiFormControl-root, 
        .MuiInputBase-root{
            height: 100%;
            width: 100%;
        }

        .MuiInputBase-root{
            border-radius: rem(8px);
            // padding-right: 9px;

            .MuiInputBase-input{
                font-size: $fs-h5;
                line-height: $lh-h5;
                font-weight: $font-regular;
                color: $clr-dark;
            }

            .MuiButtonBase-root{
                // display: none;
            }

            .MuiOutlinedInput-notchedOutline{
                border-color: $clr-dark;
            }

            &.Mui-focused{
                .MuiOutlinedInput-notchedOutline{
                    border-color: $clr-dark;
                }
            }

            .MuiAutocomplete-popupIndicator{
                display: none;
            }
        }
        .MuiAutocomplete-hasClearIcon{
            #{$self}__search-input{
                .MuiInputBase-root{
                    padding-right: 9px;
                }

            }
        }
    }

    &__action-wrap{
        display: flex;
        align-items: center;
        gap: rem(30px);

        @include mq-below($md){
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: rem(20px);
            width: 100%;
        }

        #{$self}__btn{
            height: rem(62px);

            @include mq-below($md){
                width: 100%;
            }

            &--lg{
                width: rem(452px);

                @include mq-below($md){
                    width: 100%;
                }

                @include mq-between($sm2, $lg){
                    @include orientation(portrait){
                        width: auto;
                    }
                }
            }
        }
    }

    &__cont-wrap{
        display: flex;
        justify-content: space-between;
        gap: 0 rem(90px);

        @include mq-below($md){
            flex-direction: column;
            gap: rem(24px) 0;
        }

        @include mq-between($sm2, $lg){
            @include orientation(portrait){
                flex-direction: column;
                gap: rem(36px) 0;
            }
        }
    }

    &__map-wrap{
        border-radius: rem(24px);
        overflow: hidden;
        flex-grow: 1;
        position: relative;

        @include mq-below($md){
            border-radius: rem(12px);
            width: 100%;
            height: 50vh;
            // position: sticky;
            // top: rem(94px);
        }

        @include mq-between($sm2, $lg){
            @include orientation(portrait){
                border-radius: rem(12px);
                width: 100%;
                height: 50vh;
            }
        }
    }

    &__legend-wrap{
        position: absolute;
        bottom: rem(20px);
        left: rem(20px);
        // background-color: $clr-white;
        border-radius: rem(12px);
        display: flex;
        align-items: center;
        gap: rem(20px);
        overflow: hidden;


        @include mq-below($md){
            // width: 100%;
            left: rem(10px);
            // right: 0;
            bottom: rem(10px);
            border-radius: 0 rem(12px) 0 rem(12px);
            // background-color: $clr-light;
            justify-content: space-evenly;
            flex-direction: column;
            gap: rem(10px);
            // padding: rem(6px) rem(12px);
        }
    }

    &__legend{
        $legend:&;
        display: flex;
        align-items: center;
        gap: rem(6px);
        cursor: pointer;
        padding: rem(12px) rem(24px);
        background-color: $clr-white;
        border-radius: rem(40px);


        @include mq-below($md){
            padding: rem(6px) rem(12px);
            width: rem(180px);
        }

        // &--active{

        // }

        &--first{
            &--active{
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    // background-color: $clr-dark;
                }
                #{$self}__legend-text{
                    // color: $clr-dark;
                    // color: $clr-disable;
                }

                #{$self}__legend-check-icon{
                    &.icon-circle-empty{
                        @include svg-icon("../../images/icons/circle-tick.svg", rem(21px), rem(21px));
                    }
                }
            }
        }

        &--second{
            &--active{
                position: relative;
                &:before{
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    // background-color: $clr-dark;
                }
                #{$self}__legend-text{
                    // color: $clr-dark;
                    // color: $clr-disable;
                }

                #{$self}__legend-check-icon{
                    &.icon-circle-empty{
                        @include svg-icon("../../images/icons/circle-tick.svg", rem(21px), rem(21px));
                    }
                }
            }
        }


    }

    &__legend-check-icon{
        margin-inline-start: rem(20px);
        width: rem(24px) !important;
        height: rem(24px) !important;

        @include mq-below($md){
            position: absolute;
            right: rem(10px);
            margin-inline-start: 0;
            width: rem(18px) !important;
            height: rem(18px) !important;
        }
    }

    &__legend-text{
        font-size: $fs-h7;
        line-height: $lh-h7;
        font-weight: $font-semi-bold;
        position: relative;
        // color: $clr-disable;

        @include mq-below($md){
            font-size: $fs-h10;
            line-height: $lh-h10;
        }
    }

    &__legend-icon{
        background-color: $clr-light-2 !important;
        @include mq-below($md){
            width: rem(18px) !important;
            height: rem(18px) !important;
        }
        &--clr-orange{
            background-color: $clr-orange !important;
        }
    }

    .autocomplete-container {
        position: absolute;
        top: -75px;
    }

    
}



.MuiAutocomplete-popper{

    .MuiAutocomplete-option{
        display: flex;
        align-items: center;
        gap: rem(16px);

        &:hover{
            background-color: $clr-light;
        }

        .text{
            font-size: $fs-h5;
            line-height: $lh-h5;
            color: $clr-dark;
        }

        .icon{
            width: rem(25px);
            height: rem(25px);
        }

        &.Mui-focused,
        &[aria-selected="true"],
        &.Mui-focusVisible{
            background-color: $clr-dark !important;
            color: $clr-white !important;

            .text{
                color: $clr-white;
            }
            .icon{
                background-color: $clr-white;
            }

            &:hover{
                background-color: $clr-dark !important;
                color: $clr-white !important;
            }
        }
    }

}

.gm-style-iw.gm-style-iw-c{
    padding: 0 !important;
}

.gm-style-iw-chr{
    position: absolute;
    top: 0;
    right: 0;
}