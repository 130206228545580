.bs-checkbox {
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
        &:checked + label:after {
            content: "";
            display: block;
            position: absolute;
            top: rem(4px);
            left: rem(10px);
            width: rem(9px);
            height: rem(18px);
            border: solid $clr-white;
            border-width: 0 rem(4px) rem(4px) 0;
            transform: rotate(45deg);
            @include mq-below($sm){
                width: rem(6px);
                height: rem(12px);
                border-width: 0 rem(2px) rem(2px) 0;
                top: rem(5px);
                left: rem(9px);
            }
        }
        &:checked + label:before {
            background: $clr-dark;
        }
    }
    &__label {
        position: relative;
        cursor: pointer;
        font-family: $font1;
        font-size: $fs-h5;
        line-height: normal;
        color: $clr-dark;
        text-align: left;
        @include mq-below($sm) {
            font-size: $fs-h7;
        }
        &::before {
            content: "";
            -webkit-appearance: none;
            background-color: transparent;
            border: 1px solid $clr-dark;
            // padding: 10px;
            width: rem(32px);
            height: rem(32px);
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-inline-end: rem(10px);
            border-radius: rem(4px);
            @include mq-below($sm) {
                width: rem(24px);
                height: rem(24px);
            }
        }
    }
    &__link {
        text-decoration: underline !important;
    }
}
