.lyt-features {
    display: flex;
    gap: rem(70px);
    margin-block-end: rem(48px);
    justify-content: center;

    @include mq-below($sm) {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        margin-block-end: rem(24px);
        gap: rem(24px) rem(16px);
        padding-inline: rem(20px);
    }

    @include mq-between($sm, $md2) {
        @include orientation(portrait) {
            grid-template-columns: repeat(4, 25%);
            display: grid;
            gap: rem(16px);
            grid-template-columns: repeat(3, 1fr);
            padding-inline: rem(32px);
            gap: rem(64px) 0;
            margin-block-end: 0;
        }
    }

    @include mq-between($sm2, $lg) {
        @include orientation(portrait) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: rem(64px) 0;
            margin-block-end: 0;
        }
    }
}