//lyt-img-list
.lyt-img-list {
    $self: &;
    display: grid;
    grid-template-columns: repeat(6, rem(280px));
    gap: rem(12px);
    justify-content: center;
    margin-block-end: rem(50px);
    @include mq-below($sm) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include mq-between($sm, $md2){
        grid-template-columns: repeat(3, 1fr);
        @include orientation(landscape){
            grid-template-columns: repeat(6, 1fr);
        }
    }

    > a {
        overflow: hidden;
        border-radius: rem(16px);
        height: rem(280px);
        display: none;
        &:nth-child(-n + 6) {
            display: block;
        }
        @include mq-below($sm) {
            height: rem(160px);
        }

        img{
            transform: scale(1.05);
            transition: all 0.3s ease;
        }

        &:hover{
            img{
                transform: scale(1);
            }
        }
    }
}
