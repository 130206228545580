.icon {
    display: inline-block;

    &.icon-search {
        @include svg-icon("../../images/icons/search.svg", rem(24px), rem(24px));
        @include background-opacity($clr-dark, 1);
    }
    &.icon-menu {
        @include svg-icon("../../images/icons/menu.svg", rem(24px), rem(24px));
        @include background-opacity($clr-dark, 1);
    }
    &.icon-close {
        @include svg-icon("../../images/icons/close.svg", rem(24px), rem(24px));
        @include background-opacity($clr-dark, 1);
    }
    &.icon-simple-close {
        @include svg-icon("../../images/icons/simple-close.svg", rem(12px), rem(12px));
        @include background-opacity($clr-dark, 1);
    }

    &.arrow-left {
        @include svg-icon("../../images/icons/arrow-left.svg", rem(50px), rem(50px));
        @include background-opacity($clr-dark, 1);
    }

    &.arrow-right {
        @include svg-icon("../../images/icons/arrow-right.svg", rem(50px), rem(50px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-facebook {
        @include svg-icon("../../images/icons/facebook.svg", rem(28px), rem(28px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-youtube {
        @include svg-icon("../../images/icons/youtube.svg", rem(28px), rem(28px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-instagram {
        @include svg-icon("../../images/icons/instagram.svg", rem(28px), rem(28px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-linkedin {
        @include svg-icon("../../images/icons/linkedin.svg", rem(28px), rem(28px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-quote-start {
        @include svg-icon("../../images/icons/quote-start.svg", rem(58px), rem(42px));
        @include background-opacity($clr-light, 1);
    }

    &.icon-quote-end {
        @include svg-icon("../../images/icons/quote-end.svg", rem(58px), rem(42px));
        @include background-opacity($clr-light, 1);
    }

    &.icon-angle-down {
        @include svg-icon("../../images/icons/angle-down.svg", rem(18px), rem(18px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-play {
        @include svg-icon("../../images/icons/play.svg", rem(80px), rem(80px));
        @include background-opacity($clr-white, 1);
    }

    &.icon-mute {
        @include svg-icon("../../images/icons/mute.svg", rem(32px), rem(32px));
        @include background-opacity($clr-white, 1);
    }

    &.icon-unmute {
        @include svg-icon("../../images/icons/unmute.svg", rem(32px), rem(32px));
        @include background-opacity($clr-white, 1);
    }

    &.icon-solar {
        @include svg-icon("../../images/icons/solar.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-delete {
        @include svg-icon("../../images/icons/delete.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-cotton {
        @include svg-icon("../../images/icons/cotton.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-recycle {
        @include svg-icon("../../images/icons/recycle.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-down {
        @include svg-icon("../../images/icons/down.svg", rem(50px), rem(50px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-chair {
        @include svg-icon("../../images/icons/chair.svg", rem(30px), rem(30px));
        @include background-opacity($clr-white, 1);
    }

    &.icon-curtain {
        @include svg-icon("../../images/icons/curtain.svg", rem(30px), rem(30px));
        @include background-opacity($clr-white, 1);
    }

    &.icon-filter {
        @include svg-icon("../../images/icons/filter.svg", rem(22px), rem(10px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-share {
        @include svg-icon("../../images/icons/share.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-doc {
        @include svg-icon("../../images/icons/doc.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-like {
        @include svg-icon("../../images/icons/like.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-contact {
        @include svg-icon("../../images/icons/contact.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-wash-30 {
        @include svg-icon("../../images/icons/wash-30.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-no-bleach {
        @include svg-icon("../../images/icons/no-bleach.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-tumble-dry {
        @include svg-icon("../../images/icons/tumble-dry.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-dry-clean {
        @include svg-icon("../../images/icons/dry-clean.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-iron {
        @include svg-icon("../../images/icons/iron.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-non-toxic {
        @include svg-icon("../../images/icons/non-toxic.svg", rem(60px), rem(60px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-sustainably {
        @include svg-icon("../../images/icons/sustainably.svg", rem(60px), rem(60px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-touch {
        @include svg-icon("../../images/icons/touch.svg", rem(60px), rem(60px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-washable {
        @include svg-icon("../../images/icons/washable.svg", rem(60px), rem(60px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-quality {
        @include svg-icon("../../images/icons/quality.svg", rem(60px), rem(60px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-social {
        @include svg-icon("../../images/icons/social.svg", rem(60px), rem(60px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-check-circle {
        @include svg-icon("../../images/icons/check-circle.svg", rem(96px), rem(96px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-back {
        @include svg-icon("../../images/icons/back.svg", rem(40px), rem(40px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-pin{
        @include svg-icon("../../images/icons/map-pin.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark, 1);
    }
    &.icon-copy{
        @include svg-icon("../../images/icons/copy-link.svg", rem(30px), rem(30px));
        @include background-opacity($clr-white, 1);
    }

    &.icon-truck{
        @include svg-icon("../../images/icons/truck.svg", rem(160px), rem(160px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-yarn{
        @include svg-icon("../../images/icons/yarn.svg", rem(160px), rem(160px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-drop {
        @include svg-icon("../../images/icons/drop.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-energy {
        @include svg-icon("../../images/icons/energy.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-gases {
        @include svg-icon("../../images/icons/gases.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-power-station {
        @include svg-icon("../../images/icons/power-station.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-water-harvest {
        @include svg-icon("../../images/icons/water-harvest.svg", rem(80px), rem(80px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-direction {
        @include svg-icon("../../images/icons/direction.svg", rem(28px), rem(28px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-reset {
        @include svg-icon("../../images/icons/reset.svg", rem(24px), rem(24px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-user {
        @include svg-icon("../../images/icons/user.svg", rem(24px), rem(24px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-circle-tick {
        @include svg-icon("../../images/icons/circle-tick.svg", rem(24px), rem(24px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-circle-empty {
        @include svg-icon("../../images/icons/circle-empty.svg", rem(24px), rem(24px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-sad{
        @include svg-icon("../../images/icons/sad.svg", rem(100px), rem(100px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-filter-2 {
        @include svg-icon("../../images/icons/filter-2.svg", rem(24px), rem(24px));
        @include background-opacity($clr-dark, 1);
    }

    &.icon-left{
        @include svg-icon("../../images/icons/left.svg", rem(30px), rem(30px));
        @include background-opacity($clr-dark-2, 1);
    }
    
    
}