//lyt-home-loader
.lyt-home-loader{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;

    &__image{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}