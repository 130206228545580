//mod-search-item
.mod-search-item{
    $self:&;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: rem(12px);
    // max-width: rem(500px);
    padding: rem(16px);
    background-color: $clr-light;
    position: relative;
    overflow: hidden;

    &__img-wrap{
        // width: rem(100px);
        width: 100%;
        // height: rem(100px);
        height: rem(350px);
        border-radius: rem(12px);
        overflow: hidden;
    }

    &__image{
        object-fit: cover;
        width: 100%;
    }

    &__info-wrap{
        flex-grow: 1;
        // width: calc(100% - rem(130px));
        text-align: left;
        margin-block-start: rem(24px);
    }

    &__title{
        font-size: $fs-h5;
        line-height: $lh-h5;
        font-weight: $font-medium;
        margin-block-end: rem(8px);
    }

    &__desc{
        line-height: $lh-h8;
    }

    &__link{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        transition: all 0.3s ease;
        border:1px solid transparent;
        border-radius: rem(12px);

        &:hover{
            border-color: $clr-dark;
        }
    }

}