//mod-message
.mod-message{
    text-align: center;

    &__icon{
        display: inline-block;
        margin-block-end: rem(22px);

        @include mq-below($sm){
            width: rem(60px) !important;
            height: rem(60px) !important;
            margin-block-end: rem(16px);
        }
    }

    &__title{
        font-size: $fs-h3;
        line-height: $lh-h3;
        font-weight: $font-semi-bold;
        margin-block-end: rem(16px);

        @include mq-below($sm){
            font-size: $fs-h6;
            line-height: $lh-h6;
            margin-block-end: rem(8px);
        }
    }

    &__desc{
        font-size: $fs-h6;
        line-height: $lh-h6;
        font-weight: $font-regular;

        @include mq-below($sm){
            font-size: $fs-h8;
            line-height: $lh-h8;
        }
    }


}