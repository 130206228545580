//bs-no-results
.bs-no-results{
    text-align: center;
    margin-block-end: rem(60px);

    &__icon{
        &.icon{
            &.icon-sad{
                width: rem(200px);
                height: rem(200px);
            }
        }
    }

    &__icon-wrap{
        margin-block-end: 0;
    }

    &__text{
        font-size: $fs-h3;
        line-height: $lh-h3;
        font-weight: $font-medium;

        @include mq-below($sm){
            font-size: $fs-h5;
            line-height: $lh-h5;
        }

        &--bold{
            font-weight: $font-bold;
        }
    }



}