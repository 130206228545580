$clr-dark: #513117;
$clr-dark-1: #41250f;
$clr-dark-2: #846449;
$clr-dark-3: #A8795A;
$clr-light: #f3efe7;
$clr-light-2: #c3a289;
$clr-grey: #d4cec9;
$clr-white: #ffffff;
$clr-white-1: #f7f7f7;
$clr-error: #841515;
$clr-orange: #0D5640;
$clr-disable: #cccccc;

$font1: "Poppins", sans-serif;
$font2: "Baskervville", serif;
$font3: "Sacramento", cursive;
$font4: "Libre Baskerville", serif;

/* font-weights */
$font-thin: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;

/* breakpoints */
$micro: 320px;
$mini: 360px;
$xxxs: 375px;
$xxs: 416px;
$xs: 576px;
$sm: 768px;
$sm2: 800px;
$md: 992px;
$md2: 1024px;
$lg: 1200px;
$lg2: 1366px;
$xl: 1440px;
$xxl: 1680px;
$xxxl: 1920px;

$fs-h1: rem(100px);
$lh-h1: rem(130px);
$fs-h1-md: rem(80px);
$lh-h1-md: rem(100px);
$fs-h1-sm: rem(64px);
$lh-h1-sm: rem(70px);
$fs-h1-lg: rem(58px);
$lh-h1-lg: rem(64px);
$fs-h1-mb: rem(50px);
$lh-h1-mb: rem(65px);
$fs-h2: rem(44px);
$lh-h2: rem(50px);
$fs-h3: rem(32px);
$lh-h3: rem(36px);
$fs-h4: rem(28px);
$lh-h4: rem(32px);
$fs-h5: rem(24px);
$lh-h5: rem(36px);
$fs-h6: rem(20px);
$lh-h6: rem(24px);
$fs-h7: rem(18px);
$lh-h7: rem(22px);
$fs-h8: rem(16px);
$lh-h8: rem(20px);
$fs-h9: rem(14px);
$lh-h9: rem(18px);
$fs-h10: rem(12px);
$lh-h10: rem(16px);
$fs-h11: rem(10px);
