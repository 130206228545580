//bs-collection
.bs-collection{
    $self: &;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;



    &__title{
        font-size: $fs-h3;
        line-height: $lh-h3;
        text-transform: uppercase;
        margin-block-end: rem(10px);
        @include mq-below($sm){
            font-size: $fs-h5;
            line-height: $lh-h5;
        }
    }

    &__media-wrap{
        width: rem(435px);
        height: rem(624px);
        border-radius: rem(16px);
        overflow: hidden;
        position: relative;

        @include mq-below($sm){
            width: rem(276px);
            height: rem(390px);
        }

        @include mq-between($sm2, $lg){
            @include orientation(portrait){
                width: rem(323px);
                height: rem(464px);
            }
        }

        &:before{
            content: '';
            height: rem(80px);
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.590095413165266) 100%);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }
    }

    &__image{
        transform: scale(1.05);
        transition: all 0.3s ease;
    }

    &__link{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        text-indent: -9999px;
        z-index: 1;

        &:hover{
            +#{$self}__image{
                transform: scale(1);
            }
        }
    }

    &__more-action{
        height: rem(30px);
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: rem(20px);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 rem(12px)
        
    }

    &__action{
        height: rem(30px);
        transition: all 0.3s ease;

        &:hover{
            transform: scale(1.2);
        }
    }

    &__cont-wrap{
        position: relative;

        &::before{
            content: '';
            position: absolute;
            left: rem(-30px);
            right: rem(30px);
            top: rem(30px);
            bottom: rem(-30px);
            border-radius: rem(16px);
            background-color: $clr-light;
            @include mq-below($sm){
                display: none;
            }
        }
    }


}