//bs-menu
.bs-menu {
    $self:&;
    &__nav-item {
        cursor: pointer;
        height: rem(156px);
        display: flex;
        align-items: center;
        // width: rem(200px);
        justify-content: center;

        @include mq-below($md) {
            width: 100%;
            border-bottom: 1px solid $clr-light;
            height: initial;
            justify-content: flex-start;
        }

        @include mq-between($md2, $lg){
            @include orientation(portrait){
                width: 100%;
                border-bottom: 1px solid $clr-light;
                height: initial;
                justify-content: flex-start;
            }
        }

        &:last-child {
            @include mq-below($md) {
                border: none;
            }

            @include mq-between($md2, $lg){
                @include orientation(portrait){
                    border: none;
                }
            }
        }

        #{$self}__sub-menu{
            // display: none;
            @include mq-below($md){
                transform: rotateX(150%);
                visibility: hidden;
                opacity: 0;
            }

            @include mq-between($md2, $lg){
                @include orientation(portrait){
                    transform: rotateX(150%);
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }

        &:hover,
        &--active{
            #{$self}__nav-link{
                // font-weight: $font-medium;
                font-weight: $font-semi-bold;
                // color: $clr-dark-2;
            }

            #{$self}__sub-menu{
                @include mq-above($md2){
                    transform: rotateX(0);
                    visibility: visible;
                    opacity: 1;
                }

                // @include mq-between($md2, $lg){
                //     @include orientation(portrait){
                //         transform: rotateX(0);
                //         visibility: visible;
                //         opacity: 1;
                //     }
                // }
            }
        }

        &--active{
            #{$self}__sub-menu{
                @include mq-below($md){
                    transform: rotateX(0);
                    visibility: visible;
                    opacity: 1;
                }

                // @include mq-between($md2, $lg){
                //     @include orientation(portrait){
                //         transform: rotateX(0);
                //         visibility: visible;
                //         opacity: 1;
                //     }
                // }
            }
        }

        &--our-journey{
            width: rem(126px);
            @include mq-below($sm) {
                width: 100%;
            }

            @include mq-between($sm, $lg){
                @include orientation(portrait){
                    width: 100%;
                }
            }
        }

        &--fabric-collections{
            width: rem(191px);

            @include mq-below($sm) {
                width: 100%;
            }

            @include mq-between($sm, $lg){
                @include orientation(portrait){
                    width: 100%;
                }
            }
        }

        &--sustainability{
            width: rem(142px);

            @include mq-below($sm) {
                width: 100%;
            }

            @include mq-between($sm, $lg){
                @include orientation(portrait){
                    width: 100%;
                }
            }
        }

        &--quality-standards{
            width: rem(189px);

            @include mq-below($sm) {
                width: 100%;
            }

            @include mq-between($sm, $lg){
                @include orientation(portrait){
                    width: 100%;
                }
            }
        }

        &--inspired-living{
            width: rem(142px);

            @include mq-below($sm) {
                width: 100%;
            }

            @include mq-between($sm, $lg){
                @include orientation(portrait){
                    width: 100%;
                }
            }
        }

        &--where-to-buy{
            width: rem(131px);

            @include mq-below($sm) {
                width: 100%;
            }

            @include mq-between($sm, $lg){
                @include orientation(portrait){
                    width: 100%;
                }
            }
        }

        &--contact-us{
            width: rem(117px);

            @include mq-below($sm) {
                width: 100%;
            }

            @include mq-between($sm, $lg){
                @include orientation(portrait){
                    width: 100%;
                }
            }
        }
    }

    &__nav-list {
        display: flex;
        align-items: center;
        gap: rem(48px);

        @include mq-below($md) {
            flex-direction: column;
            gap: 0;
            align-items: flex-start;
        }

        @include mq-between($md2, $lg){
            @include orientation(portrait){
                flex-direction: column;
                gap: 0;
                align-items: flex-start;
            }
        }
    }

    &__nav-link {
        font-size: $fs-h7;
        line-height: $lh-h7;
        font-weight: $font-regular;
        text-transform: uppercase;
        transition: all 0.3s ease;
        position: relative;

        @include mq-below($md) {
            padding: rem(24px) rem(20px);
            display: block;
            font-size: $fs-h5;
            line-height: $lh-h5;
            width: 100%;
        }

        @include mq-between($md2, $lg){
            @include orientation(portrait){
                padding: rem(24px) rem(20px);
                display: block;
                font-size: $fs-h5;
                line-height: $lh-h5;
                width: 100%;
            }
        }

        &:hover,
        &--active {
            // font-weight: $font-medium;
            font-weight: $font-semi-bold;
            // color: $clr-dark-2;
        }

        #{$self}__icon-more{
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            right: rem(20px);
            width: rem(35px);
            height: rem(35px);

            @include mq-between($sm, $md2){
                @include orientation(landscape){
                    display: none;
                }
            }
        }
    }
}
