//bs-foot-print
.bs-foot-print{
    $self:&;
    text-align: left;
    margin-block-start: rem(60px);

    @include mq-below($md){
        margin-block-start: rem(32px);
    }

    &__info-title{
        font-size: $fs-h1-sm;
        line-height: $lh-h1-sm;
        text-transform: uppercase;
        padding-block-end: rem(25px);

        @include mq-below($md){
            font-size: $fs-h3;
            line-height: $lh-h3;
            padding-block-end: rem(12px);
        }

        @include mq-between($sm, $lg){
            @include orientation(portrait){
                font-size: $fs-h3;
                line-height: $lh-h3;
            }
        }
    }

    &__info-desc{
        font-size: $fs-h6;
        line-height: $lh-h6;
        padding-block-start: rem(25px);
        border-top: 1px solid $clr-dark;

        @include mq-below($md){
            font-size: $fs-h9;
            line-height: $lh-h9;
            padding-block-start: rem(12px);
        }

        @include mq-between($sm, $lg){
            @include orientation(portrait){
                font-size: $fs-h7;
                line-height: $lh-h7;
            }
        }
    }

    &__tile{
        display: flex;
        gap: rem(100px);
        align-items: flex-end;

        @include mq-below($md){
            flex-direction: column;
            gap: rem(32px);
        }

        @include mq-between($sm, $lg){
            @include orientation(portrait){
                justify-content: space-between;
            }
        }
    }

    &__list-item{
        margin-block-end: rem(104px);
        @include mq-below($md){
            margin-block-end: rem(40px);
        }
        
        &:nth-child(even){
            position: relative;

            @include mq-below($md){
                padding-block: rem(40px);
            }

            #{$self}__tile{
                flex-direction: row-reverse;

                @include mq-below($md){
                    flex-direction: column;
                }
            }

            &:before{
                content: '';
                position: absolute;
                left: rem(-100px);
                right: rem(-100px);
                top: rem(46px);
                height: 100%;
                background-color: $clr-light;
                z-index: -1;

                @include mq-below($md){
                    top: 0;
                    bottom: 0;
                    height: auto;
                }
            }
        }

        &:last-child{
            margin-block-end: 0;
            #{$self}__tile{
                align-items: center;
            }
        }


    }

    &__media-wrap{
        width: rem(597px);
        height: rem(305px);
        border-radius: rem(24px);
        overflow: hidden;

        @include mq-below($md){
            width: 100%;
            height: auto;
            border-radius: rem(12px);
        }

        @include mq-between($sm, $lg){
            @include orientation(portrait){
                width: rem(550px);
                height: rem(281px);
                border-radius: rem(18px);
            }
        }
    }

    &__image{
        width: 100%;
        object-fit: contain;
    }

    &__info-wrap{
        width: calc(100% - rem(697px));

        @include mq-below($md){
            width: 100%;
        }
    }



}