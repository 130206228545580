//bs-modal
.bs-modal{
    $self:&;

    .MuiBackdrop-root{
        backdrop-filter: blur(10px);
    }

    &__close{
        position: absolute;
        right: rem(30px);
        top: rem(30px);
        z-index: 3;

        @include mq-below($sm){
            right: rem(20px);
            top: rem(20px);
        }

        .icon{
            width: rem(50px);
            height: rem(50px);
            background-color: $clr-light;

            @include mq-below($sm){
                width: rem(30px);
                height: rem(30px);
            }
        }
    }

    &__video{
        width: 100%;
        height: 100%;
        object-fit: fill;

        @include mq-below($sm){
            width: 90vw;
            height: 60vw;
        }
    }

    &--typ-video{
        #{$self}__wrapper{
            border-radius: rem(30px);
            background-color: $clr-white;
            width: 80vw;
            overflow: hidden;

            @include mq-below($sm){
                width: 90vw;
                height: 60vw;
                border-radius: rem(15px);
            }
        }

        #{$self}__content{
            display: flex;
        }
    }

    &--typ-sm{
        #{$self}__wrapper{
            width: rem(580px);
            border-radius: rem(16px);
            background-color: $clr-white;
            padding: rem(90px) rem(60px);

            @include mq-below($sm){
                width: 90vw;
                padding: rem(45px) rem(30px);
            }
        }

        #{$self}__close{
            .icon{
                width: rem(26px);
                height: rem(26px);
                background-color: $clr-dark;
            }
        }
    }


}