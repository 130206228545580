//bs-video
.bs-video {
    $self: &;
    width: rem(885px);
    height: rem(481px);
    border-radius: rem(24px);
    position: relative;
    overflow: hidden;
    @include mq-below($sm) {
        width: 100%;
        height: auto;
    }

    &__action {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
        .icon {
            @include mq-below($sm) {
                width: rem(48px);
                height: rem(48px);
            }
        }
    }

    &__media {
        width: 100%;
        height: 100%;
        object-fit: fill;
        scale: 1.01;
    }
}
