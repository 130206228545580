//bs-section-banner
.bs-section-banner {
    $self: &;
    // height: calc(100vh - rem(156px));
    display: flex;
    flex-direction: column;
    align-items: center;
    
    // @include mq-below($sm){
    //     height: auto;
    // }
    // @include mq-between($sm2, $md2){
    //     @include orientation(portrait){
    //         height: auto;
    //     }
    // }

    // @include mq-between($sm2, $lg){
    //     @include orientation(landscape){
    //         height: auto;
    //     }
    // }

    &--typ-full-height{
        @include mq-below($sm){
            min-height: 100svh;
        }

        @include mq-between($sm, $md2){
            // height: auto;
            min-height: initial;
        }
    }

    &:hover {
        #{$self}__image {
            transform: scale(1);
        }
    }

    &__image {
        transition: all 0.3s ease;
        transform: scale(1.05);
    }

    &__media-wrap {
        width: 100%;
        height: rem(496px);
        overflow: hidden;
        @include mq-below($sm){
            height: rem(256px);
        }
        @include mq-between($sm2, $md2){
            @include orientation(portrait){
                height: rem(450px);
            }
        }

        @include mq-between($sm2, $lg){
            @include orientation(landscape){
                height: rem(560px);
            }
        }
    }

    &__info-wrap {
        padding: rem(70px) rem(156px) rem(70px) rem(156px);

        @include mq-below($sm) {
            padding: rem(55px) rem(20px) rem(55px) rem(20px);
        }
        @include mq-between($sm2, $md2){
            @include orientation(portrait){
                padding: rem(40px) rem(50px) rem(65px) rem(50px);
            }
        }

        @include mq-between($sm2, $lg){
            @include orientation(landscape){
                padding: rem(90px);
            }
        }
    }

    &__desc-wrap{
        .bs-section__desc{
            margin-block-end: 0;

            @include mq-below($sm){
                line-height: $lh-h5;
            }
        }
    }

    .lyt-logo{
        margin-block-start: rem(56px);

        @include mq-below($sm){
            margin-block-start: rem(32px);
        }
    }

    &--no-hover-interaction{
        #{$self}__image{
            transform: scale(1);
        }
    }

    &__title-wrap{
        width: 100%;
        height: rem(200px);
        background-color: $clr-light;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq-below($sm){
            height: rem(72px);
        }

        @include mq-between($sm2, $lg){
            @include orientation(portrait){
                height: rem(100px);
            }
        }

        .bs-section__title{
            margin-block-end: 0;
        }
    }

    &--info-small{
        #{$self}__desc-wrap{
            max-width: 80%;
            margin: 0 auto;
            @include mq-below($sm){
                max-width: initial;
            }

            @include mq-between($sm2, $lg){
                @include orientation(portrait){
                    max-width: initial;
                }
            }
        }
        .bs-section__desc{
            @include mq-below($sm){
                margin-block-end: 0;
            }

            @include mq-between($sm2, $lg){
                @include orientation(portrait){
                    margin-block-end: 0;
                }
            }
        }
    }

    &--on-image{
        position: relative;

        #{$self}__info-wrap{
            position: absolute;
            top: rem(45px);
            z-index: 1;
            padding: 0;

            @include mq-below($sm){
                top: rem(24px);
            }
        }
    }
}
