//bs-floaters
.bs-floaters{
    position: fixed;
    z-index: 9;
    right: rem(16px);
    bottom: rem(16px);

    // @include mq-below($sm){
    //     right: rem(30px);
    //     bottom: auto;
    //     top: 80%;
    //     transform: translateY(-50%)
    // }

    &__btn{
        background-color: $clr-white;
        width: rem(80px);
        height: rem(80px);
        border-radius: 100%;
        -webkit-box-shadow: 0px 0px 22px -3px rgba(204,204,204,1);
        -moz-box-shadow: 0px 0px 22px -3px rgba(204,204,204,1);
        box-shadow: 0px 0px 22px -3px rgba(204,204,204,1);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;

        @include mq-below($sm){
            width: rem(50px);
            height: rem(50px);
        }

        &:hover{
            background-color: $clr-white-1;
        }

    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: rem(16px);
    }

    &__btn-text{
        font-size: $fs-h11;
        line-height: $fs-h10;
        max-width: 50%;

        @include mq-below($sm){
            display: none;
        }
    }

    &__btn{
        display: flex;
        flex-direction: column;
    }

    &__btn-icon{

        &--icon-whastapp{
            width: rem(50px);
            height: rem(50px);
            background: url('../../../assets/images/icons/whatsapp.svg') no-repeat 0 0 transparent;
            background-size: contain;

            @include mq-below($sm){
                width: rem(30px);
                height: rem(30px);
            }
        }
        
        &--icon-scroll-up{
            width: rem(40px);
            height: rem(40px);
            background: url('../../../assets/images/icons/scroll-up.svg') no-repeat 0 0 transparent;
            background-size: contain;

            @include mq-below($sm){
                width: rem(30px);
                height: rem(30px);
            }
        }
    }

}