//bs-btn
.bs-btn {
    $self:&;
    width: max-content;
    display: inline-block;
    transition: all 0.3s ease;

    &__btn-solid {
        font-size: $fs-h6;
        line-height: $lh-h6;
        text-transform: uppercase;
        font-weight: $font-bold;
        color: $clr-white;
        text-align: center;
        padding: rem(12px) rem(40px);
        border-radius: rem(14px);
        background-color: $clr-dark;
        @include mq-below($sm) {
            font-size: $fs-h8;
            line-height: $lh-h8;
            padding: rem(12px) rem(24px);
        }

        &:hover {
            background-color: $clr-dark-1;
        }

        &--inverse {
            color: $clr-dark;
            background-color: $clr-white;

            &:hover {
                background-color: $clr-white-1;
            }
        }
    }

    &__btn-icon-link{
        display: flex;
        align-items: center;
        gap: rem(6px);

        #{$self}__icon{
            width: rem(30px);
            height: rem(30px);
        }

        #{$self}__btn-text{
            font-size: $fs-h7;
            line-height: $lh-h7;
            text-transform: uppercase;
            font-weight: $font-bold;
        }
    }

    &__btn-icon-solid{
        background-color: $clr-white;
        border-radius: rem(8px);
        padding: rem(8px) rem(24px);
        display: flex;
        align-items: center;
        gap: rem(12px);
        border: 1px solid $clr-white;
        transition: all 0.3s ease;

        #{$self}__btn-text{
            font-size: $fs-h7;
            line-height: $lh-h7;
            text-transform: capitalize;
        }

        &:hover{
            background-color: $clr-white;
            border-color: $clr-dark;
        }
    }
}
