//bs-global-search

.bs-global-search{
    $self: &;

    &__head{
        margin-block: rem(100px);
        @include mq-below($sm){
            margin-block-start: rem(40px);
            margin-block-end: rem(100px);
        }
    }

    &__body{
        margin-block-start: rem(60px);

        @include mq-below($sm){
            margin-block-start: rem(32px);
        }
    }

    &__search-wrap{
        max-width: 55%;
        margin: rem(32px) auto;

        @include mq-below($sm){
            max-width: initial;
            margin: 0;
        }

        @include mq-between($sm, $lg){
            @include orientation(portrait){
                max-width: initial;
            }
        }
    }

    &__search-list-wrap{
        // max-width: 55%;
        margin: rem(54px) auto;
        text-align: left;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: rem(32px);

        @include mq-below($sm){
            max-width: initial;
            grid-template-columns: repeat(1, 1fr);
            gap: rem(16px);
            margin: rem(32px) auto;
        }

        @include mq-between($sm, $lg){
            @include orientation(portrait){
                grid-template-columns: repeat(2, 1fr);
                gap: rem(32px);
            }
        }
    }

    &__search-item-title{
        font-size: $fs-h4;
        line-height: $lh-h4;
        font-weight: $font-medium;
        margin-block-end: rem(12px);
        display: inline-block;

        &:hover{
            text-decoration: underline;
        }
    }

    &__search-item-desc{
        font-size: $fs-h8;
        line-height: $lh-h8;
    }

    .mod-search-item{
        margin: 0 auto;
        height: 100%;
    }

    &__result-item{
        text-align: left;
        margin-block-end: rem(100px);

        @include mq-below($sm){
            margin-block-end: rem(64px);
        }

        &:last-child{
            margin-block-end: 0;
        }

    }

    &__result-title{
        font-size: $fs-h2;
        line-height: $lh-h2;
        text-transform: uppercase;

        @include mq-below($md){
            font-size: $fs-h3;
            line-height: $lh-h3;
        }

        @include mq-between($sm, $lg){
            @include orientation(portrait){
                font-size: $fs-h3;
                line-height: $lh-h3;
            }
        }
    }

    &__close-icon{
        width: rem(20px) !important;
        height: rem(20px) !important;
        background-color: $clr-dark !important;

        &.icon-search{
            pointer-events: none;
        }
    }

}